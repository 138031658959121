import { Controller } from "stimulus";
import { enter, leave, toggle } from "el-transition";

export default class extends Controller {
  static targets = ["mobileFilter"]

  connect() {
    this.resizeObserver = this.handleResize.bind(this)
    window.addEventListener('resize', this.resizeObserver)
  }

  disconnect() {
    window.removeEventListener('resize', this.resizeObserver)
  }

  handleResize() {
    if (window.innerWidth >= 1024) {
      this.closeMobileFilter()
    }
  }

  openMobileFilter(){
    enter(this.mobileFilterTarget);
  }

  closeMobileFilter(){
    leave(this.mobileFilterTarget);
  }
}
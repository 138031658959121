import { Controller } from "stimulus";
import { get } from '@rails/request.js'
import TomSelect from "tom-select";

export default class extends Controller {
  static values = {
    url: String,
    labelField: String,
    valueField: String,
    searchField: String,
    currentItem: Object,
    customRenderer: String,
  };

  connect() {
    this.initializeTomSelect();
  }

  disconnect() {
    this.destroyTomSelect();
  }

  initializeTomSelect() {
    if (!this.element) return;

    this.select = new TomSelect(this.element, this.getTomSelectConfig());
  }

  virtualScrollConfig() {
    const baseConfig = this.baseConfig();
    return {
      ...baseConfig,
      plugins: [...baseConfig.plugins, 'virtual_scroll'],
      preload: 'focus',
      firstUrl: (query) => this.buildUrl(query, 1),
      load: (query, callback) => this.getData(query, callback),
      render: this.renderConfig(),
      onType: (str) => this.onType(str),
      onLoad: (options) => this.onLoad(),
      ...this.#initialSelectedOption(),
    }
  }

  #currentItemExists() {
    return Object.keys(this.currentItemValue).length > 0;
  }

  #initialSelectedOption(){
    if (this.#currentItemExists()) {
      return {
        options: [this.currentItemValue],
        items: [this.currentItemValue.value]
      }
    } else {
      {}
    }
  }

  baseConfig() {
    return {
      onDropdownOpen: (dropdown) => this.#rePopulateSearchInput(dropdown),
      plugins: ['dropdown_input', 'remove_button', 'clear_button'],
      valueField: this.valueFieldValue || 'value',
      labelField: this.labelFieldValue || 'text',
      searchField: [this.searchFieldValue || 'text'],
      maxOptions: null,
      hideSelected: true,
      sortField: { field: this.labelFieldValue || 'text', direction: 'asc' },
    };
  }

  set queryString(value) {
    this.queryStringValue = value;
  }

  get queryString() {
    if(this.queryStringValue) {
      return this.queryStringValue;
    } else {
      return '';
    }
  }

  #rePopulateSearchInput(dropdown) {
    // When the dropdown opens the search/query input field value is
    // normally cleared.
    // This results in a filtered list with a blank query field which is
    // inconsistent.
    // So, we store the search/query term (in `getData`) and then re-populate
    // the value here.
    const searchInputField = dropdown.querySelector(
        ".dropdown-input-wrap > .dropdown-input"
    );
    searchInputField.value = this.queryString;
  }

  getTomSelectConfig() {
    return this.urlValue ? this.virtualScrollConfig() : this.baseConfig();
  }

  async getData(query, callback) {
    // Store the query string so that we can use it in `onDropdownOpen`
    // to re-populate the input query/search field.
    this.queryString = query;
    const url = this.select.getUrl(query);
    const response = await get(url)

    if (response.ok) {
      const json = await response.json;
      this.handleGetResponse(json, query)
      callback(json.items)
    }
  }

  handleGetResponse(json, query) {
    if (json.has_more) {
      const nextUrl = this.buildUrl(query, json.next)
      this.select.setNextUrl(query, nextUrl);
    }
  }

  buildUrl(query, page) {
    const url = new URL(this.urlValue);
    url.searchParams.append("search", query)
    url.searchParams.append("page", page)
    return url;
  }

  renderConfig() {
    return {
      loading_more: () => this.renderLoadingMore(),
      no_more_results: () => this.renderNoMoreResults(),
      no_results: () => this.renderNoResults(),
      ...this.#customRenderer()
    };
  }

  #customRenderer() {
    if (this.customRendererValue === 'thumbnail') {
      return this.#thumbnailRenderers()
    }
    return {};
  }

  #thumbnailRenderers() {
    return {
      option: this.#thumbnailRenderer,
      item: this.#thumbnailRenderer,
    }
  }

  // Expects `object` to have the following members:
  // `src` - URL or path to the thumbnail
  // `text` - description to be displayed alongside the thumbnail image
  #thumbnailRenderer(object, escape) {
    return `<div><img class="small" src="${object.src}">${escape(object.text)}</div>`;
  }

  onType(str) {
    this.select.clearOptions();
    this.select.load(str);
  }

  // Tom Select have know issue when you type something and scroll down to fetch more result, it will jump to top again,
  // this code maintains the last position during scroll.
  onLoad() {
    const currentScrollPosition = this.select.dropdown_content.scrollTop;
    requestAnimationFrame(() => {
      this.select.dropdown_content.scrollTop = currentScrollPosition;
    });
  }

  renderLoadingMore() {
    return `<div class="loading-more-results"><div class="spinner"></div></div>`;
  }

  renderNoMoreResults() {
    return '<div class="no-more-results">No more results</div>';
  }

  renderNoResults() {
    return '<div class="no-results">No results found</div>'
  }

  destroyTomSelect() {
    if (this.select) {
      this.select.destroy();
    }
  }
}